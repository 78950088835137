<template>
  <div class="manage-report-definitions">
    <b-dropdown v-if="showButton"
                :disabled="reportDownloadProcessing"
                extra-toggle-classes="nav-link-custom"
                :toggle-class="toggleClass"
                :variant="variant"
                :no-caret="noCaret"
                menu-class="p-2 report-dropdown"
                right
                lazy
                class="p-0"
                @show="loadReportItems($event)">
      <template #button-content>
        <slot name="button-content">
          <template v-if="reportDownloadProcessing || reportStillProcessing">
            <fluency-icon type="loop" class="rotating p-0 m-0"></fluency-icon>
            <span v-if="selectedAccounts.length > 3 && reportDownloadPercentage > 0">{{$filters.percentage(reportDownloadPercentage, 0)}}</span>
          </template>
          {{label}}
        </slot>
      </template>
      <template v-if="pptReportDefinitions.length > 0">
        <b-dropdown-header>Presentations</b-dropdown-header>
        <b-dropdown-item-button :key='`report-dropdown-ppt-${reportIndex}`'
                         v-for="(report, reportIndex) in pptReportDefinitions"
                                @click="runReportAgainstAccounts(report, 'PPT')">
          <div class="d-flex justify-content-between" style="white-space: normal;">
            <div>{{report.reportName}}</div>
            <fluency-icon type="download" class="ml-2 mt-2"></fluency-icon>
          </div>
        </b-dropdown-item-button>
      </template>
      <template v-if="isLoading">
        <fluency-loader show dots />
      </template>
      <template v-if="!isLoading && !hasReportsAvailable">
        <b-dropdown-text>No reports available. <nuxt-link to="/reporting/">Create one now.</nuxt-link></b-dropdown-text>
      </template>
      <template v-if="fluencyReports.length > 0">
        <b-dropdown-header>Fluency Reports</b-dropdown-header>
        <b-dropdown-item-button :key='`report-dropdown-fluency-${reportIndex}`'
                         v-for="(report, reportIndex) in fluencyReportDefinitions"
                         @click="runReportAgainstAccounts(report, 'FLUENCY')">
          <div  class="d-flex justify-content-between" style="white-space: normal;">
            <div>{{report.reportName}}</div>
            <fluency-icon type="play" class="ml-2 mt-2"></fluency-icon>
          </div>
        </b-dropdown-item-button>
      </template>
      <b-dropdown-divider v-if="fluencyReports.length > 0 && reportDefinitions.length > 0 && selectedAccounts.length > 0"/>
      <template v-if="reportDefinitions.length > 0 && selectedAccounts.length > 0">
        <b-dropdown-header>Google Reports</b-dropdown-header>
        <b-dropdown-item-button :key='`report-dropdown-google-${reportIndex}`'
                         v-for="(report, reportIndex) in reportDefinitions"
                         @click="runReportAgainstAccounts(report, 'GOOGLE')">
          <div  class="d-flex justify-content-between" style="white-space: normal;">
            <div>{{report.name}}</div>
            <fluency-icon type="play" class="ml-2 mt-2"></fluency-icon>
          </div>
        </b-dropdown-item-button>
      </template>
    </b-dropdown>
    <html-report-preview ref="reportPreviewer"
                         :report="previewReport"
                         :accounts-prop="selectedAccounts.map(a => a.accountPlanId)"
                         :show-btn="false"></html-report-preview>
    <async-fetcher ref="asyncFetcher" file hide-loader></async-fetcher>
  </div>
</template>

<script>
import Papa from 'papaparse'
import HtmlReportPreview from '@/components/pages/reporting/htmlReportPreview'
import AsyncFetcher from 'core-ui/components/common/asyncFetcher'
import { downloadFile } from 'core-ui/utils/downloadFile'

export default {
  name: 'manageReportDefinitions',
  components: { AsyncFetcher, HtmlReportPreview },
  props: {
    selectedAccounts: {
      type: Array,
      default: () => []
    },
    label: {
      type: String
    },
    variant: {
      type: String
    },
    showButton: {
      type: Boolean,
      default: true
    },
    noCaret: {
      type: Boolean,
      default: false
    },
    toggleClass: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      reportDownloadProcessing: false,
      selectedCount: 0,
      fluencyReports: [],
      pptReports: [],
      previewReport: null,
      showPreview: false,
      reportDownloadPercentage: 0,
      presentationProcessProgress: 0,
      loading: {
        definitions: false,
        fluency: false,
        ppts: false
      }
    }
  },
  mounted () {
  },
  watch: {
  },
  computed: {
    accountPlanIdsString () {
      const accountPlanIds = this.selectedAccounts.map(a => a.accountPlanId)
      accountPlanIds.sort()
      return accountPlanIds.join(',')
    },
    isLoading () {
      return Object.values(this.loading).some(val => val)
    },
    reportStillProcessing () {
      const response = this.$store.getters.reportRequestResponse
      if (response) {
        return !response.complete && !response.base64EncodedContents
      }
      return false
    },
    hasReportsAvailable () {
      return (this.reportDefinitions?.length > 0 && this.selectedAccounts?.length > 0) || this.fluencyReportDefinitions?.length > 0
    },
    reportDefinition () {
      return this.$store.getters.activeReportDefinition || []
    },
    reportDefinitions () {
      return this.$store.getters.reportDefinitions || []
    },
    fluencyReportDefinitions () {
      if (this.selectedAccounts?.length > 0) {
        return this.fluencyReports || []
      } else {
        return this.fluencyReports?.filter(report => report.scope !== 'ACCOUNT') || []
      }
    },
    pptReportDefinitions () {
      if (this.selectedAccounts?.length > 0) {
        return this.pptReports || []
      } else {
        return this.pptReports?.filter(report => report.scope !== 'ACCOUNT') || []
      }
    },
    allReports () {
      return {
        Presentations: this.pptReports,
        Fluency: this.fluencyReports,
        Google: this.reportDefinitions
      }
    }
  },
  methods: {
    async getPresentations () {
      this.loading.ppts = true
      try {
        const response = await this.$res.reporting.reports({
          format: 'PPT'
        })
        this.pptReports = response || []
      } catch (err) {}
      this.loading.ppts = false
    },
    async getFluencyReports () {
      this.loading.fluency = true
      try {
        const response = await this.$res.reporting.reports({
          format: 'PDF'
        })
        this.fluencyReports = response || []
      } catch (err) {}
      this.loading.fluency = false
    },
    async runReportAgainstAccounts (report, type) {
      if (type === 'GOOGLE') {
        this.downloadReportCsv(report)
      } else if (type === 'PPT') {
        this.downloadPresentation(report)
      } else if (type === 'FLUENCY') {
        this.previewReport = report
        this.$nextTick(() => {
          this.$refs.reportPreviewer.preview()
        })
      }
    },
    async downloadReportCsv (report) {
      this.reportDownloadProcessing = true

      await this.$store.dispatch('fetchReportDownload', {
        ...report,
        accountPlans: this.selectedAccounts
      })
      const reportDownload = this.$store.getters.reportDownload
      const allItemsParsed = reportDownload
        .flatMap(account => Papa.parse(account.report, { ...this.csvParserConfig, preview: 0 }).data)
        .filter(row => Object.keys(row).length === report.selectedFields.length)
      const csvContent = Papa.unparse(allItemsParsed)
      const csvData = new Blob([csvContent], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvData)
      const link = document.createElement('a')
      link.setAttribute('href', csvUrl)
      link.setAttribute('download', 'export.csv')
      link.click()

      this.reportDownloadProcessing = false
    },
    async downloadPresentation (report) {
      this.reportDownloadProcessing = true
      const showPercentage = this.selectedAccounts.length > 3
      if (showPercentage) {
        this.reportDownloadPercentage = 0.01
      }
      for (const account of this.selectedAccounts) {
        await this.processPresentation(account, report, showPercentage)
      }
      this.presentationProcessProgress = 0
      this.reportDownloadPercentage = 0
      this.reportDownloadProcessing = false
    },
    async processPresentation (account, report, showPercentage) {
      const accountPlanId = account.accountPlanId
      const ppt = await this.asyncFetchReport(report, accountPlanId)
      if (!ppt) {
        this.$toast('Error receiving presentation data', 'danger')
        return
      }
      downloadFile(ppt, 'data:attachment/powerpoint', accountPlanId + '_' + report.reportName + '.pptx')
      this.presentationProcessProgress++
      if (showPercentage) {
        this.reportDownloadPercentage = this.presentationProcessProgress / this.selectedAccounts.length
      }
    },
    asyncFetchReport (report, accountPlanId) {
      return new Promise((resolve, reject) => {
        const callback = (ppt) => {
          ppt ? resolve(ppt) : reject(ppt)
        }
        if (this.$refs.asyncFetcher) {
          this.$refs.asyncFetcher.asyncFetch(this.$res.reporting.asyncProcessPresentation, [report, accountPlanId], callback)
        }
      })
    },
    async loadReportItems (event) {
      this.loading.fluency = true
      this.loading.ppts = true
      if (this.reportDefinitions.length < 1) {
        this.loading.definitions = true
        this.$store.dispatch('fetchReportDefinitions').then(res => {
          this.loading.definitions = false
        }).catch(() => {
          this.loading.definitions = false
        })
      }
      if (this.fluencyReports.length < 1) {
        this.getFluencyReports()
      }
      if (this.pptReports.length < 1) {
        this.getPresentations()
      }
    }
  }
}
</script>

<style lang="scss">
.manage-report-definitions .report-dropdown {
  max-height: 80vh;
  overflow-y: auto;
}
</style>
